import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Billing } from '../models/usage.model';
import { UsageGateway } from '../usecases/usage.gateway';

@Injectable()
export class UsageService extends UsageGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  getBilling(year: number, month: number): Observable<Billing> {
    const params = { year, month };
    return this._http.get<Billing>('api/rss/usages/cws', { params });
  }
}
