import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { LibModule, httpInterceptorProviders } from '@daikin-tic/dxone-com-lib';

import { AccessPointInteractor } from './interactors/access-point.interactor';
import { ThinkletInteractor } from './interactors/thinklet.interactor';
import { UsageInteractor } from './interactors/usage.interactor';
import { WifiNetworkInteractor } from './interactors/wifi-network.interactor';
import { AccessPointService } from './services/access-point.service';
import { ThinkletService } from './services/thinklet.service';
import { UsageService } from './services/usage.service';
import { WifiNetworkService } from './services/wifi-network.service';
import { AccessPointGateway } from './usecases/access-point.gateway';
import { AccessPointUsecase } from './usecases/access-point.usecase';
import { ThinkletGateway } from './usecases/thinklet.gateway';
import { ThinkletUsecase } from './usecases/thinklet.usecase';
import { UsageGateway } from './usecases/usage.gateway';
import { UsageUsecase } from './usecases/usage.usecase';
import { WifiNetworkGateway } from './usecases/wifi-network.gateway';
import { WifiNetworkUsecase } from './usecases/wifi-network.usecase';

@NgModule({
  providers: [
    httpInterceptorProviders,
    { provide: AccessPointUsecase, useClass: AccessPointInteractor },
    { provide: ThinkletUsecase, useClass: ThinkletInteractor },
    { provide: UsageUsecase, useClass: UsageInteractor },
    { provide: WifiNetworkUsecase, useClass: WifiNetworkInteractor },
    { provide: AccessPointGateway, useClass: AccessPointService },
    { provide: ThinkletGateway, useClass: ThinkletService },
    { provide: UsageGateway, useClass: UsageService },
    { provide: WifiNetworkGateway, useClass: WifiNetworkService },
  ],
  imports: [CommonModule, LibModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule Only.');
    }
  }
}
