import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { NeverError } from '../models/error.model';
import { User, UserCreateParams, UserUpdateParams, Users } from '../models/user.model';
import { DistinctSubject, recursiveQuery } from '../models/utility.model';
import { WebSocketSyncData } from '../models/web-socket.model';
import { UserGateway } from '../usecases/user.gateway';
import { UserUsecase } from '../usecases/user.usecase';
import { WebSocketUsecase } from '../usecases/web-socket.usecase';

@Injectable()
export class UserInteractor extends UserUsecase {
  get users$(): Observable<Users> {
    return this._users.pipe(
      map(users =>
        users.values().map(user => ({
          ...user,
          trackingDevices$: recursiveQuery(params => this._userGateway.listUserTrackingDevices(user.userId, params), {}),
        })),
      ),
      map(users => new Users(users)),
    );
  }

  private readonly _users = new DistinctSubject<Users>(new Users());

  constructor(
    private _webSocketUsecase: WebSocketUsecase,
    private _userGateway: UserGateway,
  ) {
    super();

    if (!this._webSocketUsecase.enabled) {
      return;
    }
    this._webSocketUsecase.isOpen$.subscribe(isOpen => (isOpen ? this.onSignIn() : this.onSignOut()));
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'user'),
        map(({ data }) => data as WebSocketSyncData<User>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'create':
          case 'update':
            this._users.next(this._users.value.set(data.payload));
            break;
          case 'delete':
            this._users.next(this._users.value.delete(data.payload.userId));
            break;
          default:
            throw new NeverError(data.reason);
        }
      });
  }

  createUser(params: UserCreateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._userGateway.createUser(params).subscribe({
      next: createdUser => this._users.next(this._users.value.set(createdUser)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateUser(userId: string, params: UserUpdateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._userGateway.updateUser(userId, params).subscribe({
      next: updatedUser => this._users.next(this._users.value.set(updatedUser)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteUser(userId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._userGateway.deleteUser(userId).subscribe({
      next: () => this._users.next(this._users.value.delete(userId)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  resendUserInvite(userId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._userGateway.resendUserInvite(userId).subscribe({
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteUserTrackingDevices(userId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._userGateway.deleteUserTrackingDevices(userId).subscribe({
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateUserRestriction(userId: string, enabled: boolean): Observable<never> {
    const result = new AsyncSubject<never>();
    this._userGateway.updateUserRestriction(userId, { enabled }).subscribe({
      next: updatedUser => this._users.next(this._users.value.set(updatedUser)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  private onSignIn(): void {
    recursiveQuery(params => this._userGateway.listUsers(params), {}).subscribe(users => {
      this._users.next(new Users(users));
    });
  }

  private onSignOut(): void {
    this._users.next(new Users());
  }
}
