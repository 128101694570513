import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { AuthUsecase, DistinctSubject, recursiveQuery } from '@daikin-tic/dxone-com-lib';

import { AccessPointCreateParams, AccessPoints } from '../models/access-point.model';
import { AccessPointGateway } from '../usecases/access-point.gateway';
import { AccessPointUsecase } from '../usecases/access-point.usecase';

@Injectable()
export class AccessPointInteractor extends AccessPointUsecase {
  get accessPoints$(): Observable<AccessPoints> {
    return this._accessPoints;
  }

  private readonly _accessPoints = new DistinctSubject<AccessPoints>(new AccessPoints());

  constructor(
    private _authUsecase: AuthUsecase,
    private _accessPointGateway: AccessPointGateway,
  ) {
    super();

    this._authUsecase.authState$
      .pipe(
        map(({ status }) => status === 'signedIn'),
        distinctUntilChanged(),
      )
      .subscribe(signedIn => (signedIn ? this.onSignIn() : this.onSignOut()));
  }

  reload(): void {
    this.onSignIn();
  }

  createAccessPoint(params: AccessPointCreateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._accessPointGateway.createAccessPoint(params).subscribe({
      next: createdAccessPoint => this._accessPoints.next(this._accessPoints.value.set(createdAccessPoint)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteAccessPoint(accessPointId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._accessPointGateway.deleteAccessPoint(accessPointId).subscribe({
      next: () => this._accessPoints.next(this._accessPoints.value.delete(accessPointId)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  private onSignIn(): void {
    recursiveQuery(params => this._accessPointGateway.listAccessPoints(params), {}).subscribe(accessPoints => {
      this._accessPoints.next(new AccessPoints(accessPoints));
    });
  }

  private onSignOut(): void {
    this._accessPoints.next(new AccessPoints());
  }
}
