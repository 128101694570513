import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Location } from '../models/location.model';
import { DistinctSubject, recursiveQuery } from '../models/utility.model';
import { AuthUsecase } from '../usecases/auth.usecase';
import { LocationGateway } from '../usecases/location.gateway';
import { LocationUsecase } from '../usecases/location.usecase';

@Injectable()
export class LocationInteractor extends LocationUsecase {
  get locations$(): Observable<Location[]> {
    return this._locations;
  }

  private readonly _locations = new DistinctSubject<Location[]>([]);

  constructor(
    private _authUsecase: AuthUsecase,
    private _locationGateway: LocationGateway,
  ) {
    super();
    this._authUsecase.authState$
      .pipe(
        map(({ status }) => status === 'signedIn'),
        distinctUntilChanged(),
      )
      .subscribe(signedIn => (signedIn ? this.onSignIn() : this.onSignOut()));
  }

  private onSignIn(): void {
    recursiveQuery(params => this._locationGateway.listLocations(params), {}).subscribe(locations => this._locations.next(locations));
  }

  private onSignOut(): void {
    this._locations.next([]);
  }
}
