import { Injectable } from '@angular/core';
import { AsyncSubject, BehaviorSubject, EMPTY, Observable, concat, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap } from 'rxjs/operators';

import { AuthPayload, AuthState, AuthUser } from '../models/auth.model';
import { AuthError } from '../models/error.model';
import { AuthGateway } from '../usecases/auth.gateway';
import { AuthUsecase } from '../usecases/auth.usecase';
import { ProgressUsecase } from '../usecases/progress.usecase';

const FACTOR_ATTR = 'phone_number';

@Injectable()
export class AuthInteractor extends AuthUsecase {
  get authState$(): Observable<AuthState> {
    return this._authState.pipe(filter(({ status }) => status !== 'none'));
  }
  get token$(): Observable<string> {
    return this._authGateway.currentSession().pipe(map(session => session.token));
  }
  get payload$(): Observable<AuthPayload> {
    return this._authGateway.currentSession().pipe(map(session => session.payload));
  }

  private readonly _authState = new BehaviorSubject<AuthState>({ status: 'none' });

  constructor(
    private _progressUsecase: ProgressUsecase,
    private _authGateway: AuthGateway,
  ) {
    super();
    this.refreshCache();
  }

  refreshCache(): Observable<never> {
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .currentUser(true)
      .pipe(
        catchError(() => of(undefined)),
        finalize(() => this._progressUsecase.dismiss(progressId)),
      )
      .subscribe({
        next: user => {
          if (user) {
            const verified = user.preferredMFA === 'SMS_MFA' && user.attributes?.phone_number_verified;
            const optional = user.getSignInUserSession()?.getIdToken().payload['mfa'] === 'optional';
            this.setAuthState({ status: verified || optional ? 'signedIn' : 'requireMfa', user });
          } else {
            this.setAuthState({ status: 'signIn', user });
          }
        },
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  signIn(username: string, password: string): Observable<never> {
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .signIn(username, password)
      .pipe(finalize(() => this._progressUsecase.dismiss(progressId)))
      .subscribe({
        next: user => {
          if (!user.challengeName) {
            const verified = user.preferredMFA === 'SMS_MFA' && user.attributes?.phone_number_verified;
            const optional = user.getSignInUserSession()?.getIdToken().payload['mfa'] === 'optional';
            this.setAuthState({ status: verified || optional ? 'signedIn' : 'requireMfa', user });
            return;
          }
          switch (user.challengeName) {
            case 'SMS_MFA':
              this.setAuthState({ status: 'confirmSignIn', user });
              break;
            case 'NEW_PASSWORD_REQUIRED':
              this.setAuthState({ status: 'requireNewPassword', user });
              break;
            default:
              result.error(new Error());
              break;
          }
        },
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  confirmSignIn(code: string): Observable<never> {
    const { user } = this._authState.value;
    if (!user) {
      throw new Error('Not signed in.');
    }
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .confirmSignIn(user, code)
      .pipe(finalize(() => this._progressUsecase.dismiss(progressId)))
      .subscribe({
        next: signedUser => {
          this.setAuthState({ status: 'signedIn', user: signedUser });
        },
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  completeNewPassword(password: string): Observable<never> {
    const { user } = this._authState.value;
    if (!user) {
      throw new Error('Not signed in.');
    }
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .completeNewPassword(user, password)
      .pipe(finalize(() => this._progressUsecase.dismiss(progressId)))
      .subscribe({
        next: () => {
          const username = user.challengeParam?.userAttributes.email;
          this.setAuthState({ status: 'signIn', user: { username } as AuthUser });
        },
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  signOut(): Observable<never> {
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .signOut()
      .pipe(finalize(() => this._progressUsecase.dismiss(progressId)))
      .subscribe({
        next: () => {
          this.setAuthState({ status: 'signIn' });
        },
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  forgotPassword(username: string): Observable<never> {
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .forgotPassword(username)
      .pipe(finalize(() => this._progressUsecase.dismiss(progressId)))
      .subscribe({
        next: () => {
          this.setAuthState({ status: 'resetPassword', user: { username } as AuthUser });
        },
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  forgotPasswordSubmit(username: string, code: string, password: string): Observable<never> {
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .forgotPasswordSubmit(username, code, password)
      .pipe(finalize(() => this._progressUsecase.dismiss(progressId)))
      .subscribe({
        next: () => {
          this.setAuthState({ status: 'signIn' });
        },
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  changePassword(oldPassword: string, newPassword: string): Observable<never> {
    const { user } = this._authState.value;
    if (!user) {
      throw new Error('Not signed in.');
    }
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .changePassword(user, oldPassword, newPassword)
      .pipe(finalize(() => this._progressUsecase.dismiss(progressId)))
      .subscribe({
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  verifyAuthFactor(phoneNumber: string): Observable<never> {
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .currentUser(true)
      .pipe(
        mergeMap(user => {
          if (user.preferredMFA === 'SMS_MFA' && user.attributes?.phone_number_verified) {
            this.setAuthState({ status: 'signedIn', user });
            return throwError(new AuthError('AlreadyEnabledException'));
          }
          return concat(
            this._authGateway.updateUserAttribute(user, FACTOR_ATTR, phoneNumber),
            this._authGateway.verifyUserAttribute(user, FACTOR_ATTR),
          );
        }),
        finalize(() => this._progressUsecase.dismiss(progressId)),
      )
      .subscribe({
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  verifyAuthFactorSubmit(code: string): Observable<never> {
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .currentUser(true)
      .pipe(
        mergeMap(user => {
          if (user.preferredMFA === 'SMS_MFA' && user.attributes?.phone_number_verified) {
            this.setAuthState({ status: 'signedIn', user });
            return throwError(new AuthError('AlreadyEnabledException'));
          }
          return concat(
            this._authGateway.verifyUserAttributeSubmit(user, FACTOR_ATTR, code),
            this._authGateway.setPreferredMFA(user, 'SMS'),
            this._authGateway.currentUser(),
          );
        }),
        finalize(() => this._progressUsecase.dismiss(progressId)),
      )
      .subscribe({
        next: signedUser => {
          this.setAuthState({ status: 'signedIn', user: signedUser });
        },
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  deleteAuthFactor(): Observable<never> {
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .currentUser(true)
      .pipe(
        mergeMap(user =>
          user.preferredMFA === 'NOMFA'
            ? EMPTY
            : concat(
                this._authGateway.setPreferredMFA(user, 'NOMFA'),
                this._authGateway.deleteUserAttribute(user, FACTOR_ATTR),
                this._authGateway.currentUser(),
              ),
        ),
        finalize(() => this._progressUsecase.dismiss(progressId)),
      )
      .subscribe({
        next: signedUser => {
          const optional = signedUser.getSignInUserSession()?.getIdToken().payload['mfa'] === 'optional';
          this.setAuthState({ status: optional ? 'signedIn' : 'requireMfa', user: signedUser });
        },
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  setAuthState(state: AuthState): void {
    this._authState.next(state);
  }
}
