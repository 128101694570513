import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { NeverError } from '../models/error.model';
import { Group, GroupCreateParams, GroupUpdateParams, Groups } from '../models/group.model';
import { DistinctSubject, recursiveQuery } from '../models/utility.model';
import { WebSocketSyncData } from '../models/web-socket.model';
import { GroupGateway } from '../usecases/group.gateway';
import { GroupUsecase } from '../usecases/group.usecsae';
import { WebSocketUsecase } from '../usecases/web-socket.usecase';

@Injectable()
export class GroupInteractor extends GroupUsecase {
  get groups$(): Observable<Groups> {
    return this._groups;
  }

  private _groups: DistinctSubject<Groups> = new DistinctSubject(new Groups());

  constructor(
    private _webSocketUsecase: WebSocketUsecase,
    private _groupGateway: GroupGateway,
  ) {
    super();

    if (!this._webSocketUsecase.enabled) {
      return;
    }
    this._webSocketUsecase.isOpen$.subscribe(isOpen => (isOpen ? this.onSignIn() : this.onSignOut()));
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'group'),
        map(({ data }) => data as WebSocketSyncData<Group>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'create':
          case 'update':
            this._groups.next(this._groups.value.set(data.payload));
            break;
          case 'delete':
            this._groups.next(this._groups.value.delete(data.payload.groupId));
            break;
          default:
            throw new NeverError(data.reason);
        }
      });
  }

  createGroup(params: GroupCreateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._groupGateway.createGroup(params).subscribe({
      next: createdGroup => this._groups.next(this._groups.value.set(createdGroup)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result;
  }

  updateGroup(groupId: string, params: GroupUpdateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._groupGateway.updateGroup(groupId, params).subscribe({
      next: updatedGroup => this._groups.next(this._groups.value.set(updatedGroup)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result;
  }

  deleteGroup(groupId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._groupGateway.deleteGroup(groupId).subscribe({
      next: () => this._groups.next(this._groups.value.delete(groupId)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result;
  }

  private onSignIn(): void {
    recursiveQuery(params => this._groupGateway.listGroups(params), {}).subscribe(groups => this._groups.next(new Groups(groups)));
  }

  private onSignOut(): void {
    this._groups.next(new Groups());
  }
}
