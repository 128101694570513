export const BILLING_START_YEAR = 2022;
export const BILLING_START_MONTH = 6;

export type RemoteSupportPlan = 'none' | 'lite' | 'basic100' | 'basic200';
export type TranscribePlan = 'none' | 'ja';

export type BillingAmount = {
  basic: number;
  extra: number;
};

export type BillingRecord<T> = {
  plan: T;
  usage: BillingAmount;
  cost: BillingAmount;
};

export type BillingFeature = {
  id: string;
  remoteSupport: BillingRecord<RemoteSupportPlan>;
  transcribe: BillingRecord<TranscribePlan>;
};

export type Billing = {
  contract: number;
  activate: number;
  cost: number;
  thinklets: BillingFeature[];
  others: BillingFeature;
};
