import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { BehaviorSubject, Observable, Subject, partition } from 'rxjs';
import { delayWhen, distinctUntilChanged, filter, finalize, map, repeatWhen, takeUntil } from 'rxjs/operators';

import { AuthUsecase, DistinctSubject } from '@daikin-tic/dxone-com-lib';

import { BILLING_START_MONTH, BILLING_START_YEAR, Billing } from '../models/usage.model';
import { UsageGateway } from '../usecases/usage.gateway';
import { UsageUsecase } from '../usecases/usage.usecase';

@Injectable()
export class UsageInteractor extends UsageUsecase {
  get year$(): Observable<number> {
    return this._date.pipe(
      map(date => date.get('year')),
      distinctUntilChanged(),
    );
  }
  get month$(): Observable<number> {
    return this._date.pipe(
      map(date => date.get('month')),
      distinctUntilChanged(),
    );
  }
  get billing$(): Observable<Billing | undefined> {
    return this._billing;
  }
  get error$(): Observable<unknown> {
    return this._error;
  }

  private readonly _date = new BehaviorSubject<dayjs.Dayjs>(dayjs());
  private readonly _billing = new DistinctSubject<Billing | undefined>(undefined);
  private readonly _error = new Subject<unknown>();

  constructor(private _authUsecase: AuthUsecase, private _usageGateway: UsageGateway) {
    super();

    const [signIn$, signOut$] = partition(
      this._authUsecase.authState$.pipe(
        map(({ status }) => status === 'signedIn'),
        distinctUntilChanged(),
      ),
      signedIn => signedIn,
    );

    signIn$.subscribe(() => {
      this._date.next(dayjs());
    });

    this._date
      .pipe(
        filter(date => {
          const min = dayjs().set({ year: BILLING_START_YEAR, month: BILLING_START_MONTH - 1 });
          const max = dayjs().add(1, 'month');
          return date.isBetween(min, max, 'month', '[]');
        }),
        takeUntil(signOut$),
        finalize(() => this._billing.next(undefined)),
        repeatWhen(notifications => notifications.pipe(delayWhen(() => signIn$))),
      )
      .subscribe(date => {
        this._usageGateway.getBilling(date.get('year'), date.get('month') + 1).subscribe({
          next: billing => this._billing.next(billing),
          error: err => {
            this._billing.next(undefined);
            this._error.next(err);
          },
        });
      });
  }

  changeYear(year: number): void {
    this.changeDate(year, this._date.value.get('month'));
  }

  changeMonth(month: number): void {
    this.changeDate(this._date.value.get('year'), month);
  }

  private changeDate(year: number, month: number): void {
    const min = dayjs().set({ year: BILLING_START_YEAR, month: BILLING_START_MONTH - 1 });
    const max = dayjs().add(1, 'month');
    const date = dayjs().set({ year, month });

    if (date.isSame(this._date.value, 'month')) {
      return;
    } else if (date.isBefore(min, 'month')) {
      this._date.next(min);
    } else if (date.isAfter(max, 'month')) {
      this._date.next(max);
    } else {
      this._date.next(date);
    }
  }
}
