import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { AuthUsecase, DistinctSubject, recursiveQuery } from '@daikin-tic/dxone-com-lib';

import { WifiNetworkCreateParams, WifiNetworks } from '../models/wifi-network.model';
import { WifiNetworkGateway } from '../usecases/wifi-network.gateway';
import { WifiNetworkUsecase } from '../usecases/wifi-network.usecase';

@Injectable()
export class WifiNetworkInteractor extends WifiNetworkUsecase {
  get wifiNetworks$(): Observable<WifiNetworks> {
    return this._wifiNetworks;
  }

  private readonly _wifiNetworks = new DistinctSubject<WifiNetworks>(new WifiNetworks());

  constructor(private _authUsecase: AuthUsecase, private _wifiNetworkGateway: WifiNetworkGateway) {
    super();

    this._authUsecase.authState$
      .pipe(
        map(({ status }) => status === 'signedIn'),
        distinctUntilChanged(),
      )
      .subscribe(signedIn => (signedIn ? this.onSignIn() : this.onSignOut()));
  }

  reload(): void {
    this.onSignIn();
  }

  createWifiNetwork(params: WifiNetworkCreateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._wifiNetworkGateway.createWifiNetwork(params).subscribe({
      next: createdWifiNetwork => this._wifiNetworks.next(this._wifiNetworks.value.set(createdWifiNetwork)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteWifiNetwork(wifiNetworkId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._wifiNetworkGateway.deleteWifiNetwork(wifiNetworkId).subscribe({
      next: () => this._wifiNetworks.next(this._wifiNetworks.value.delete(wifiNetworkId)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  private onSignIn(): void {
    recursiveQuery(params => this._wifiNetworkGateway.listWifiNetworks(params), {}).subscribe(wifiNetworks => {
      this._wifiNetworks.next(new WifiNetworks(wifiNetworks));
    });
  }

  private onSignOut(): void {
    this._wifiNetworks.next(new WifiNetworks());
  }
}
