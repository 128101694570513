import { Observable } from 'rxjs';

import { Billing } from '../models/usage.model';

export abstract class UsageUsecase {
  abstract get year$(): Observable<number>;
  abstract get month$(): Observable<number>;
  abstract get billing$(): Observable<Billing | undefined>;
  abstract get error$(): Observable<unknown>;

  abstract changeYear(year: number): void;
  abstract changeMonth(month: number): void;
}
