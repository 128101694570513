import { HttpContextToken } from '@angular/common/http';
import dayjs from 'dayjs';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, expand, toArray } from 'rxjs/operators';

// cspell:disable-next-line
export const DATE_PARSE_FORMAT = 'YYYYMMDD';

export const MAXIMUM_PERIOD_DAYS = 30;
export const MAXIMUM_DELAY_MINUTES = 15;

export const HIDE_PROGRESS = new HttpContextToken(() => false);

export type Period = { from: number; to: number };

export type RequiredOnly<T> = { [P in keyof T as T[P] extends Required<T>[P] ? P : never]: T[P] };

export type Prop<T, K> = { [P in keyof T]-?: T[P] extends K | undefined ? P : never }[keyof T];

export type ListResponse<T> = { items: T[]; nextToken?: string };

export type QueryParams = { nextToken?: string };

export type QueryFunction<T extends QueryParams, R> = (params: T) => Observable<ListResponse<R>>;

export function recursiveQuery<T extends QueryParams, R>(func: QueryFunction<T, R>, params: T): Observable<R[]> {
  return func(params).pipe(
    expand(({ nextToken }) => (nextToken ? func({ ...params, nextToken }) : EMPTY)),
    concatMap(({ items }) => items),
    toArray(),
    catchError(() => of([] as R[])),
  );
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

export function isObject(value: unknown): value is Record<string, unknown> {
  return value !== null && typeof value === 'object';
}

export function toStrictEntries<T extends Record<string, unknown>>(value: T): [keyof T, unknown][] {
  return Object.entries(value);
}

export class DistinctSubject<T> extends BehaviorSubject<T> {
  override next(value: T): void {
    if (this.value !== value) {
      super.next(value);
    }
  }
}

export class PeriodSubject extends DistinctSubject<Period> {
  private readonly _daysBefore: number;
  private readonly _daysAfter: number;

  constructor(initialValue: Period, daysBefore: number = 6, daysAfter: number = 0) {
    super(initialValue);
    this._daysBefore = daysBefore;
    this._daysAfter = daysAfter;
  }

  now(): void {
    const from = dayjs().tz().startOf('day').subtract(this._daysBefore, 'days');
    const to = dayjs().tz().startOf('day').add(this._daysAfter, 'days');
    this.next({
      from: from.unix(),
      to: to.unix(),
    });
  }

  includes(timestamp: number): boolean {
    return this.value.from <= timestamp && timestamp <= this.value.to;
  }

  tz(timezone: string): void {
    if (this.value.from == null || this.value.to == null) {
      return;
    }
    const from = dayjs.tz(dayjs.unix(this.value.from).tz().format(DATE_PARSE_FORMAT), timezone).startOf('day');
    const to = dayjs.tz(dayjs.unix(this.value.to).tz().format(DATE_PARSE_FORMAT), timezone).endOf('day');
    const now = dayjs().tz(timezone);
    this.next({
      from: (from.isBefore(now) ? from : now.startOf('day')).unix(),
      to: (to.isBefore(now) ? to : now).unix(),
    });
  }
}

export abstract class ImmutableMap<K, V extends { version: number }> {
  get size(): number {
    return this._val.size;
  }

  private _key: (value: V) => K;
  private _val = new Map<K, V>();

  constructor(keySelector: (value: V) => K, entries: V[] | Iterable<V> = []) {
    this._key = keySelector;
    for (const value of entries) {
      this._val.set(this._key(value), value);
    }
  }

  protected abstract clone(entries?: V[] | Iterable<V>): this;

  set(value: V): this {
    const key = this._key(value);
    const clone = new Map<K, V>(this._val);
    const current = clone.get(key);
    if (!current || current.version < value.version) {
      return this.clone(clone.set(key, value).values());
    }
    return this;
  }

  delete(key: K): this {
    const clone = new Map<K, V>(this._val);
    if (clone.delete(key)) {
      return this.clone(clone.values());
    }
    return this;
  }

  get(key: K): V | undefined {
    return this._val.get(key);
  }

  has(key: K): boolean {
    return this._val.has(key);
  }

  values(): V[] {
    return [...this._val.values()];
  }

  filter<P extends Prop<V, string | number | boolean>>(prop: P, value: V[P] | ((v: V[P]) => boolean)): this {
    const filterFn = (v: V) => (value instanceof Function ? value(v[prop]) : value === v[prop]);
    return this.clone(this.values().filter(filterFn));
  }

  sort<P extends Prop<V, string | number | boolean>>(prop: P, order: 'asc' | 'desc' = 'asc'): this {
    const compareFn = (a: V, b: V) => {
      const x = a[prop];
      const y = b[prop];
      if (x === y) {
        return 0;
      }
      if (isString(x) && isString(y)) {
        return x.localeCompare(y) * (order === 'asc' ? 1 : -1);
      }
      if (isNumber(x) && isNumber(y)) {
        return (x - y) * (order === 'asc' ? 1 : -1);
      }
      return (x > y ? 1 : -1) * (order === 'asc' ? 1 : -1);
    };
    return this.clone(this.values().sort(compareFn));
  }
}
