import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { NeverError } from '../models/error.model';
import {
  SupportTeam,
  SupportTeamCreateParams,
  SupportTeamUpdateParams,
  SupportTeams,
  SupportTeamsUpdateParams,
} from '../models/support-team.model';
import { DistinctSubject, recursiveQuery } from '../models/utility.model';
import { WebSocketSyncData } from '../models/web-socket.model';
import { SupportTeamGateway } from '../usecases/support-team.gateway';
import { SupportTeamUsecase } from '../usecases/support-team.usecase';
import { WebSocketUsecase } from '../usecases/web-socket.usecase';

@Injectable()
export class SupportTeamInteractor extends SupportTeamUsecase {
  get supportTeams$(): Observable<SupportTeams> {
    return this._supportTeams;
  }

  private readonly _supportTeams = new DistinctSubject<SupportTeams>(new SupportTeams());

  constructor(
    private _webSocketUsecase: WebSocketUsecase,
    private _supportTeamsGateway: SupportTeamGateway,
  ) {
    super();

    if (!this._webSocketUsecase.enabled) {
      return;
    }
    this._webSocketUsecase.isOpen$.subscribe(isOpen => (isOpen ? this.onSignIn() : this.onSignOut()));
    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'support-team'),
        map(({ data }) => data as WebSocketSyncData<SupportTeam>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'create':
          case 'update':
            this._supportTeams.next(this._supportTeams.value.set(data.payload));
            break;
          case 'delete':
            this._supportTeams.next(this._supportTeams.value.delete(data.payload.teamId));
            break;
          default:
            throw new NeverError(data.reason);
        }
      });
  }

  createSupportTeam(params: SupportTeamCreateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._supportTeamsGateway.createSupportTeam(params).subscribe({
      next: createdSupportTeam => this._supportTeams.next(this._supportTeams.value.set(createdSupportTeam)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result;
  }

  updateSupportTeam(teamId: string, params: SupportTeamUpdateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._supportTeamsGateway.updateSupportTeam(teamId, params).subscribe({
      next: updatedSupportTeam => this._supportTeams.next(this._supportTeams.value.set(updatedSupportTeam)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result;
  }

  updateSupportTeams(params: SupportTeamsUpdateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._supportTeamsGateway.updateSupportTeams(params).subscribe({
      next: ({ items }) => {
        const supportTeams = items.reduce((acc, cur) => acc.set(cur), this._supportTeams.value);
        this._supportTeams.next(supportTeams);
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result;
  }

  deleteSupportTeam(teamId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._supportTeamsGateway.deleteSupportTeam(teamId).subscribe({
      next: () => this._supportTeams.next(this._supportTeams.value.delete(teamId)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result;
  }

  private onSignIn(): void {
    recursiveQuery(params => this._supportTeamsGateway.listSupportTeams(params), {}).subscribe(supportTeams =>
      this._supportTeams.next(new SupportTeams(supportTeams)),
    );
  }

  private onSignOut(): void {
    this._supportTeams.next(new SupportTeams());
  }
}
